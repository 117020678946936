import React, { useEffect } from "react";
const Support = () => {
  useEffect(() => {
    // Load Stripe script asynchronously
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";

    script.async = true;
    document.body.appendChild(script);

    // Clean up after component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (

    <div style={{marginTop:50}}>
      <stripe-pricing-table
        pricing-table-id="prctbl_1NdHaPCgxb4CjpGz7QXJ5SOQ"
        publishable-key="pk_live_51NQKD9Cgxb4CjpGzLlPvNYztAXUjFITFIGLJhz66efjYrwI8SlCTVS2nkedzliJXpoRuOg91BlLHmpJZHQNtel3Q00YNIkn2N2"
      ></stripe-pricing-table>
    </div>
  );
};

export default Support;



